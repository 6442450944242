import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import "../styles/zenlayer-inside.less"
import SEO from "../components/SEO"
// import { SolutionTopBanner } from "../components/block"
const loadText = require("src/utils").loadText
// const data = loadText("zenlayer-insider")
export default function Home() {
  return (
    <div>
      <SEO title="Page not found | 上海层峰" />
      <Header logo="/logo-blue.png" />
      <div
        className="font-36"
        style={{ textAlign: "center", lineHeight: "500px" }}
      >
        Sorry! That page doesn't seem to exist.
      </div>
      <Footer />
    </div>
  )
}
